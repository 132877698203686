import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BrowserStorageService } from "@app/core/services/browser-storage.service";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ProductDetailsService {
  private productDetailsKey = "nutrient-planer-product-details";

  constructor(
    private http: HttpClient,
    private browserStorageService: BrowserStorageService,
  ) {}

  checkProductsDetailsStorage(): Observable<boolean> {
    return this.getProductsDetailsFromStorage().pipe(
      map((products) => !!products.length),
    );
  }

  getProductsDetailsFromStorage(): Observable<any[]> {
    return this.browserStorageService.supported().pipe(
      map(() => this.browserStorageService.get(this.productDetailsKey)),
      map((value: string | null) => (value ? JSON.parse(value) : [])),
    );
  }

  addProductsDetailsToStorage(products: any[]): void {
    this.browserStorageService.set(
      this.productDetailsKey,
      JSON.stringify(products),
    );
  }

  loadProductsDetails(products: any): Observable<any> {
    return this.http.post<any>(`${environment.primaryApiUrl}/food`, {
      idsWithGrams: products,
    });
  }
}
